import React, { useState } from "react";
import { TextInput, Button, Paper, Text } from "@mantine/core";

const Form = ({ testType, setHasUserInfo }) => {
    const [form, setForm] = useState({
        email: "",
        name: "",
    });
    const [error, setError] = useState(null);

    // Function to handle form input change
    const handleChange = (field, event) => {
        let _form;
        _form = { ...form };
        _form[field] = event.target.value;
        setForm(_form);
    };

    // const Router = useRouter();

    // Function to submit form and call API
    const handleSubmit = async (event) => {
        setError(null);
        event.preventDefault();
        if (form.name.length > 5 && form.email.length > 5) {
            setHasUserInfo(true);
        } else {
            setError("Please fill all fields. Minimum length should be 6 characters long.");
        }
    };

    return (
        <Paper withBorder shadow="md" p={30} mt={30} radius="md" style={{ width: "420px" }}>
            <Text size="lg" weight={500} transform="capitalize">
                {testType} Screening Test
            </Text>
            {error && (
                <Text color="red" align="center" my={10}>
                    {error}
                </Text>
            )}
            <form onSubmit={handleSubmit} style={{ textAlign: "left" }}>
                <TextInput
                    type="text"
                    label="Name:"
                    placeholder="Enter your name"
                    value={form.name}
                    radius="md"
                    onChange={(event) => handleChange("name", event)}
                    style={{ width: "100%" }}
                />
                <TextInput
                    type="email"
                    label="Email:"
                    placeholder="Enter Email address"
                    value={form.email}
                    radius="md"
                    onChange={(event) => handleChange("email", event)}
                    style={{ width: "100%", margin: "20px 0" }}
                />

                <Button radius="md" style={{ width: "100%" }} type="submit">
                    Next
                </Button>
            </form>
        </Paper>
    );
};

export default Form;
