import React from "react";
import { Center, Container } from "@mantine/core";

import Form from "./Form";
import Logo from "../../shared/icons/Logo";

const UserInfo = ({ testType, setTestType, setHasUserInfo }) => {
    return (
        <div>
            <Container>
                <Logo />
                <Center>
                    <Form
                        testType={testType}
                        setHasUserInfo={setHasUserInfo}
                        setTestType={setTestType}
                    />
                </Center>
            </Container>
        </div>
    );
};

export default UserInfo;
