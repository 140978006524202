import { useEffect, useState } from "react";

import Interview from "./components/Interview";
import UserInfo from "./components/UserInfo";

import "./App.css";

function App() {
    const [hasUserInfo, setHasUserInfo] = useState(false);
    const [testType, setTestType] = useState("rust");

    useEffect(() => {
        setTestType(window.location.href.split("=")[1]);
    }, []);

    return (
        <div className="App">
            {!hasUserInfo ? (
                <UserInfo
                    testType={testType}
                    setTestType={setTestType}
                    setHasUserInfo={setHasUserInfo}
                />
            ) : (
                <Interview testType={testType} />
            )}
        </div>
    );
}

export default App;
