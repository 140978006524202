import React, { useEffect, useState } from "react";
import { Center, Container, Text, Paper } from "@mantine/core";
import moment from "moment";

import Questions from "./Questions";
import { rustQuestions } from "./rustQuestions";
import { frontendQuestions } from "./frontendQuestions";
import { backendQuestions } from "./backendQuestions";
import { pythonQuestions } from "./pythonQuestions";

import NotFound from "../../shared/404";

import Logo from "../../shared/icons/Logo";

const Interview = ({ testType }) => {
    const [displayElement, setDisplayElement] = useState(0);
    const [testDone, setTestDone] = useState(false);
    const [score, setScore] = useState(0);
    const [timer, setTimer] = useState("");
    const [minute, setMinute] = useState(0);
    const [seconds, setSeconds] = useState(0);

    console.log("test type ", testType);

    let questions;
    if (testType === "rust") questions = rustQuestions;
    if (testType === "frontend") questions = frontendQuestions;
    if (testType === "backend") questions = backendQuestions;
    if (testType === "python") questions = pythonQuestions;

    const handleNext = () => {
        let time = new Date();

        if (displayElement + 1 === questions.length) {
            setDisplayElement(displayElement + 1);
            let time1 = moment(time, "hh:mm");
            let time2 = moment(timer, "hh:mm");

            let secondsDiff = time1.diff(time2, "seconds");
            let formatted = moment.utc(secondsDiff * 1000).format("mm:ss");

            formatted = formatted.split(":");

            setMinute(formatted[0]);
            setSeconds(formatted[1]);
            setTestDone(true);
        } else {
            setDisplayElement(displayElement + 1);
        }
    };

    useEffect(() => {
        let time = new Date();
        setTimer(time);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {questions ? (
                <Container>
                    <Logo />
                    <Center>
                        {testDone ? (
                            <Paper
                                withBorder
                                shadow="md"
                                mt={20}
                                p={20}
                                radius="md"
                                style={{ width: "100%", maxWidth: "550px", textAlign: "left" }}
                            >
                                <Text mt={10} size="md" weight={500} align="center">
                                    Thank you for giving the screening test.
                                    <br />
                                    You have scored {((score / questions.length) * 100).toFixed(2)}
                                    %. and it took {minute} minutes and {seconds} seconds."
                                </Text>
                            </Paper>
                        ) : (
                            <Questions
                                testType={testType}
                                questions={questions}
                                displayElement={displayElement}
                                handleNext={handleNext}
                                setScore={setScore}
                                score={score}
                            />
                        )}
                    </Center>
                </Container>
            ) : (
                <NotFound />
            )}
        </div>
    );
};

export default Interview;
