export const rustQuestions = [
    {
        id: 1,
        question: "Which keyword is used to define Interfaces?",
        answers: [
            { answer: "fn", correct: false },
            { answer: "impl", correct: false },
            { answer: "trait", correct: true },
            { answer: "dyn", correct: false },
        ],
    },
    {
        id: 2,
        question: "Are the data members (fields) of a public structure private or public?",
        answers: [
            { answer: "depends on function", correct: false },
            { answer: "private", correct: true },
            { answer: "public", correct: false },
            { answer: "undefined", correct: false },
        ],
    },
    {
        id: 3,
        question: "What is impl keyword used for?",
        answers: [
            { answer: "For Interfaces", correct: false },
            { answer: "For Inheritance", correct: false },
            { answer: "Define a class", correct: false },
            { answer: "Implement functionality on types", correct: true },
        ],
    },
    {
        id: 4,
        question: "What is the main use of Raw pointers in Rust?",
        answers: [
            { answer: "Low level memory control", correct: false },
            { answer: "Foreign Function Interfaces", correct: true },
            { answer: "Get address of a variable", correct: false },
            { answer: "C type implementations", correct: false },
        ],
    },
    {
        id: 5,
        question: "Which one of below is not allowed by unsafe keyword?",
        answers: [
            { answer: "Turn off borrow checker", correct: true },
            { answer: "Dereference a raw pointer", correct: false },
            { answer: "Call unsafe functions", correct: false },
            { answer: "Access or update static mutable variables", correct: false },
        ],
    },
    {
        id: 6,
        question: "What is the use of 'type' keyword in rust?",
        answers: [
            { answer: "Template", correct: false },
            { answer: "User Defined data types", correct: false },
            { answer: "Dynamic Sized datatypes", correct: false },
            { answer: "alias to another type", correct: true },
        ],
    },
    {
        id: 7,
        question: "What is 'Drop' in rust used for?",
        answers: [
            { answer: "Run code in multi-threaded", correct: false },
            { answer: "Run code when variable is out of scope", correct: true },
            { answer: "Run code and top it it error comes", correct: false },
            { answer: "None of above", correct: false },
        ],
    },
    {
        id: 8,
        question: "Which one below is not a macro available in std prelude?",
        answers: [
            { answer: "todo", correct: false },
            { answer: "unreachable", correct: false },
            { answer: "macro_rules", correct: false },
            { answer: "None of above", correct: true },
        ],
    },
    {
        id: 9,
        question: "Which feature is the main reason for memory safey in Rust?",
        answers: [
            { answer: "reference", correct: false },
            { answer: "lifetimes", correct: false },
            { answer: "borrowing", correct: false },
            { answer: "ownership", correct: true },
        ],
    },
    {
        id: 10,
        question: "Can multiple variables hold ownership of same data in Rust?",
        answers: [
            { answer: "Yes", correct: true },
            { answer: "No", correct: false },
        ],
    },
    {
        id: 11,
        question:
            "Which one of following allows multiple variables to hold ownership of same data?",
        answers: [
            { answer: "RC", correct: false },
            { answer: "ARC", correct: false },
            { answer: "Both of above", correct: true },
            { answer: "None of above", correct: false },
        ],
    },
    {
        id: 12,
        question: "Which one of below cannot be used for interior mutability?",
        answers: [
            { answer: "RefCell", correct: false },
            { answer: "Cell", correct: false },
            { answer: "Mutex", correct: false },
            { answer: "None of above", correct: true },
        ],
    },
    {
        id: 13,
        question: "Which of the below is not associated with multi-threading in Rust?",
        answers: [
            { answer: "Arc", correct: false },
            { answer: "Barrier", correct: false },
            { answer: "Box", correct: true },
            { answer: "None of above", correct: false },
        ],
    },
    {
        id: 14,
        question: "Which one of below is not a multi-threading library?",
        answers: [
            { answer: "crossbeam", correct: false },
            { answer: "rayon", correct: false },
            { answer: "tokio", correct: false },
            { answer: "None of above", correct: true },
        ],
    },
    {
        id: 15,
        question: "Which of these is not a collection in std library?",
        answers: [
            { answer: "VecDeque", correct: false },
            { answer: "LinkedList", correct: false },
            { answer: "BTreeSet", correct: false },
            { answer: "None of above", correct: true },
        ],
    },

    {
        id: 16,
        question: "What is Clippy?",
        answers: [
            { answer: "Code Compressor", correct: false },
            { answer: "Code Cleaner", correct: false },
            { answer: "Code Linter", correct: true },
            { answer: "Code Formatter", correct: false },
        ],
    },
    {
        id: 17,
        question:
            "There are 3 ways to return an iterator in Rust: iter, into_iter & iter_mut. Which is the yield returned by iter?",
        answers: [
            { answer: "&T", correct: true },
            { answer: "T", correct: false },
            { answer: "mut T", correct: false },
            { answer: "&mut T", correct: false },
        ],
    },
];
