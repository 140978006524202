export const pythonQuestions = [
    {
        id: 1,
        question: "Which one of under is not a build-in python module?",
        answers: [
            { answer: "abc", correct: false },
            { answer: "hashlib", correct: false },
            { answer: "pathlib", correct: false },
            { answer: "bson", correct: true },
        ],
    },
    {
        id: 2,
        question: "Which of the following is not a keyword in Python language?",
        answers: [
            { answer: "val", correct: true },
            { answer: "raise", correct: false },
            { answer: "try", correct: false },
            { answer: "with", correct: false },
        ],
    },
    {
        id: 3,
        question:
            "Which module in the python standard library parses options received from the command line?",
        answers: [
            { answer: "getarg", correct: false },
            { answer: "getopt", correct: true },
            { answer: "main", correct: false },
            { answer: "os", correct: false },
        ],
    },
    {
        id: 4,
        question: "What is Poetry?",
        answers: [
            { answer: "Package manager", correct: false },
            { answer: "Virtual environment manager", correct: false },
            { answer: "Both of above", correct: true },
            { answer: "None of above", correct: false },
        ],
    },
    {
        id: 5,
        question: "Which one of under is not a build-in module for parallel operations?",
        answers: [
            { answer: "concurrent.futures", correct: false },
            { answer: "queue", correct: false },
            { answer: "asyncio", correct: false },
            { answer: "None of above", correct: true },
        ],
    },
    {
        id: 6,
        question: "Which one of the below is not a valid in-built python module?",
        answers: [
            { answer: "enum", correct: false },
            { answer: "dataclasses", correct: false },
            { answer: "net", correct: true },
            { answer: "pathlib", correct: false },
        ],
    },
    {
        id: 7,
        question: "Which one of the below is not a valid export from typing module?",
        answers: [
            { answer: "Union", correct: false },
            { answer: "NewType", correct: false },
            { answer: "Any", correct: false },
            { answer: "Number", correct: true },
        ],
    },
    {
        id: 8,
        question: "What is the latest version of Python?",
        answers: [
            { answer: "3.9", correct: false },
            { answer: "3.10", correct: false },
            { answer: "3.11", correct: true },
            { answer: "3.12", correct: false },
        ],
    },
    {
        id: 9,
        question: "Which new module was added in Python 3.11?",
        answers: [
            { answer: "tomllib", correct: true },
            { answer: "doctest", correct: false },
            { answer: "email", correct: false },
            { answer: "enum", correct: false },
        ],
    },
    {
        id: 10,
        question: "Which module is used for abstractions in Python?",
        answers: [
            { answer: "abc", correct: true },
            { answer: "ast", correct: false },
            { answer: "dataclasses", correct: false },
            { answer: "abstract", correct: false },
        ],
    },
];
