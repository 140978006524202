export const frontendQuestions = [
    {
        id: 1,
        question: "What is HTML?",
        answers: [
            { answer: "Hyper Text Multiple Language", correct: false },
            { answer: "Hyper Text Markup Language", correct: true },
            { answer: "Hyper Text Makeup Language", correct: false },
            { answer: "Hyper Tax Makeup Language", correct: false },
        ],
    },
    {
        id: 2,
        question: "What is CSS?",
        answers: [
            { answer: "Consistent Style Sheet", correct: false },
            { answer: "Compiled Style Sheet", correct: false },
            { answer: "Cascade Style Sheet", correct: true },
            { answer: "Cleared Style Sheet", correct: false },
        ],
    },
];
