import React, { useState } from "react";

import { Paper, Text, Chips, Chip, Progress } from "@mantine/core";

const Questions = ({ testType, questions, handleNext, displayElement, setScore, score }) => {
    const [value, setValue] = useState("");
    const [nextQuestion, setNextQuestion] = useState(false);
    const [countdown, setCountdown] = useState("Next question in 3");
    const [chipColor, setChipColor] = useState("blue");

    const handleValue = (_value) => {
        if (value) return;
        setValue(_value);
        _handleNext(_value);
    };

    const _handleNext = (_value) => {
        questions[displayElement].answers.find((answer) => {
            if (answer.answer === _value) {
                if (answer.correct) {
                    setChipColor("green");
                    setScore(score + 1);
                } else {
                    setChipColor("red");
                }
            }
        });

        setNextQuestion(true);

        let count = 3;
        let counter = setInterval(timer, 1000);
        function timer() {
            count = count - 1;
            if (count <= 0) {
                clearInterval(counter);
                //counter ended, do something here
                handleNext();
                setValue("");
                setNextQuestion(false);
                if (questions.length === displayElement + 1) {
                    setCountdown("Result in 3");
                } else {
                    setCountdown("Next question in 3");
                }
                return;
            }
            let text = `Next question in ${count}`;

            if (questions.length === displayElement + 1) {
                text = `Result in ${count}`;
            } else {
                text = `Next question in ${count}`;
            }

            setCountdown(text);
        }
    };

    return (
        <Paper
            withBorder
            shadow="md"
            p={0}
            mt={20}
            radius="md"
            style={{ width: "100%", maxWidth: "550px", textAlign: "left" }}
        >
            <Progress
                striped
                value={((displayElement + 1) / questions.length) * 100}
                label={""}
                size="sm"
                radius="sm"
            />
            <div className="row">
                <Text mt={10} size="md" weight={500} align="left" ml={20} transform="capitalize">
                    {testType} screening test
                </Text>

                <Text mt={10} size="sm" weight={500} align="right" mr={20} mb={20}>
                    {displayElement + 1} of {questions.length}
                </Text>
            </div>
            {questions.map((question, index) => (
                <>
                    {displayElement === index && (
                        <div key={index} style={{ padding: "0 20px" }}>
                            <Text size="lg" weight="500">
                                {question.question}
                            </Text>
                            <Chips
                                color={chipColor}
                                value={value}
                                onChange={(e) => handleValue(e)}
                                variant="filled"
                                spacing="sm"
                                size="md"
                                direction="column"
                                mt={20}
                            >
                                {question.answers.map((answer, index) => (
                                    <Chip
                                        key={index}
                                        value={answer.answer}
                                        defaultChecked={false}
                                        styles={{ outlineColor: chipColor }}
                                        variant="outline"
                                    >
                                        {answer.answer}
                                    </Chip>
                                ))}
                            </Chips>
                        </div>
                    )}
                </>
            ))}

            <Text radius="md" style={{ width: "90%" }} m={20} mt={25} align="center" weight={500}>
                {nextQuestion && countdown}

                {!nextQuestion && (
                    <>
                        {questions.length === displayElement + 1
                            ? "Select answer to finish"
                            : "Select answer for next question"}
                    </>
                )}
            </Text>
        </Paper>
    );
};

export default Questions;
