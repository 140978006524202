import React from "react";
import { createStyles, Container, Title, Text, SimpleGrid } from "@mantine/core";

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },

    title: {
        fontWeight: 900,
        fontSize: 34,
        marginBottom: theme.spacing.md,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,

        [theme.fn.smallerThan("sm")]: {
            fontSize: 32,
        },
    },

    control: {
        [theme.fn.smallerThan("sm")]: {
            width: "100%",
        },
    },

    mobileImage: {
        [theme.fn.largerThan("sm")]: {
            display: "none",
        },
    },

    desktopImage: {
        [theme.fn.smallerThan("sm")]: {
            display: "none",
        },
    },
}));

const NotFound = () => {
    const { classes } = useStyles();

    return (
        <Container className={classes.root}>
            <SimpleGrid spacing={20} breakpoints={[{ maxWidth: "sm", cols: 1, spacing: 20 }]}>
                <div style={{ width: "500px" }}>
                    <Title className={classes.title}>Something is not right...</Title>
                    <Text color="dimmed" size="lg">
                        Page you are trying to open does not exist. <br />
                        You may have mistyped the address, or the page has been moved to another
                        URL.
                    </Text>
                </div>
            </SimpleGrid>
        </Container>
    );
};

export default NotFound;
